import React from "react";
import { graphql } from "gatsby";

import { Container, SEOPage } from "../components";
import { Grid3, Grid210 } from "../components/css";

import HeroSearch from "cre-plugin/src/components/search";
import { Featured } from "cre-plugin/src/components/propertyComponents";

import { FaUsers, FaComments, FaKey } from "react-icons/fa";

export default function Home({ data }) {

  //On page SEO
  const pageSEO = {
    title: "Home",
    url: `/`,
    type: "page"
  }

  return (
    <Container page="content">

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        title={pageSEO.title}
        url={pageSEO.url}
        type={pageSEO.type}
      />

      <div style={{ display: "grid", alignItems: "center", backgroundImage: "url(https://res.cloudinary.com/pluto-space/image/upload/v1620907713/6108772c-c7ab-4da9-91db-50317c9601eb_ti00nr.jpg)", minHeight: 400, width: "100%", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "bottom" }}>

        <HeroSearch type="hero" state={{
          searchState: {
            sortBy: "ToLet",
            page: 1,
            range: {
              max_gla: {
                min: 0,
                max: 10000000000
              },
              min_gla: {
                min: 0,
                max: 10000000000
              }
            },
            refinementList: {
              property_category: [],
              suburb_cluster: []
            }
          }
        }} />

      </div>

      <div id="about" style={{ maxWidth: "1200px", margin: "1em auto", padding: "2rem 0" }}>

        <h2 style={{ marginTop: 0, textAlign: "center" }}>Who are we?</h2>

        <Grid3>
          <div style={{ textAlign: "center" }}>
            <FaUsers size="3em" color="#f26a37" />
            <h4>Family Owned</h4>
            <p>We are family owned commercial property company based in Strijdom Park.</p>
          </div>
          <div style={{ textAlign: "center" }}>
          <FaComments size="3em" color="#f26a37" />
            <h4>Personal Service</h4>
            <p>With us you are talking directly to the owner.</p>
          </div>
          <div style={{ textAlign: "center" }}>
          <FaKey size="3em" color="#f26a37" />
            <h4>Turnkey Solutions</h4>
            <p>We offer turnkey solutions, starting with tenant installations and maintenance, to repairs and flexible leases.</p>
          </div>
        </Grid3>

      </div>

      <div style={{ background: "#f26a37", color: "white", margin: "0" }}>
        <div style={{ maxWidth: "1200px", padding: "3rem 0", margin: "auto" }}>

          <Grid210>

            <h2 style={{ marginTop: 0 }}>Our services</h2>

            <p>We offer a range of commercial/office space in the Randburg / Strijdom Park area, ranging from 135m2 to more than 1000 m2. In Blairgowrie, we have an office building catering for variable offices from 50m2 to 600m2.</p>

          </Grid210>

        </div>

      </div>

      <div style={{ background: "#57a2b9", color: "white", margin: "0" }}>


      </div>

    </Container >
  )
}

export const query = graphql`
  query {
    featuredToLet: allD9PropertyToLet(filter: {property_featured: {eq: 1}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_gmaven_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        marketing {
          property_marketing_description
          unit_marketing_heading
          unit_marketing_description
        }
        total_property_gla
        total_erf_extent
        property_category
        property_sub_category
        unit_category
        secondary_category
        property_featured
        best_image
        status
        status_date
        web_ref
        gross_price
        net_price
        available_type
        available_date
        unit_id
        complex_space
        unit_responsibility {
          gmaven_contact_key
          cell_number
          email
          name
          image
          role
        }
        min_gla
        max_gla
        sub_divisible
        this_unit_can_be_leased_by_itself
        combinable
        property_video
        video
        property_virtual_tour
        virtual_tour
        property_update_date
        unit_update_date
        property_images {
          image_path_url
          type
        }
        office_features {
          has_aircon
          no_floors
          lift_count
          lift_cores
          has_internet
          internet_provider
        }
        industrial_features {
          power_phase
          power_output
          power_unit
          has_yard
          height_to_eaves
          lux_level
          lux_description
          gantry_cranes
          floor_load_capacity
        }
        parking {
          property_parking
          property_parking_ratio
          property_parking_open
          property_parking_basement
          property_parking_covered
          property_parking_shade
          property_parking_tandem
        }
        general_features {
          property_naming_rights
          security_guards
          security_hours
          security_responsibility
          has_security
          security_infrustructure
          green_certification
          green_certification_rating
          has_generators
          solar
          backup_water_supply
          canteen
          property_kitchenette
          gym
          boardroom
          showers
        }
      }
    }
    featuredForSale: allD9PropertyForSale(filter: {property_featured: {eq: 1}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        best_image
        _typename
        property_type
        unit_id
        min_gla
        max_gla
        total_property_gla
        total_erf_extent
        property_category
        secondary_category
        status
        status_date
        web_ref
        property_featured
        available_type
        available_date
        gross_price
        video
        virtual_tour
        complex_space
        update_date
      }
    }
  }
`